<template>
  <div class="avatar-page">
    okok

    <!-- 
    <CustomizationView
      v-if="studentCredential.subscription !== null"
      :subscription="studentCredential.subscription"
      :builder-api="'https://builder.dentrodahistoria.com.br/api'"
      :load-avatar="studentCredential.subscription.loadAvatar"
      @avatarMethods="avatarMethods"
    /> -->

    <div class="return">
      <span
        class="icon"
        @click="
          () => {
            this.$router.push({ name: 'student-step-1' });
          }
        "
        v-if="studentCredential.isTeacher"
      >
        <svg
          style="fill: black"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 36 36"
        >
          <path
            d="M15.123 11.294a1.004 1.004 0 0 1 0 1.42l-4.282 4.282h17.156a1.003 1.003 0 1 1 0 2.007H10.84l4.282 4.284a1.003 1.003 0 1 1-1.419 1.42L7 18l6.705-6.706a1.003 1.003 0 0 1 1.418 0z"
            stroke-width="1.6"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      </span>
      <span
        class="icon"
        @click="
          () => {
            this.$router.push({ name: 'student-step-1' });
          }
        "
        v-if="!studentCredential.isTeacher"
      >
        <svg
          style="fill: black"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 36 36"
        >
          <path
            d="M15.123 11.294a1.004 1.004 0 0 1 0 1.42l-4.282 4.282h17.156a1.003 1.003 0 1 1 0 2.007H10.84l4.282 4.284a1.003 1.003 0 1 1-1.419 1.42L7 18l6.705-6.706a1.003 1.003 0 0 1 1.418 0z"
            stroke-width="1.6"
            fill-rule="evenodd"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      </span>
    </div>

    <Avatar
      :character_slug="studentCredential.main_product.sku"
      :character_id="studentCredential.main_product.character_id"
      :product_id="studentCredential.main_product.builder_id"
      :is_male="studentCredential.user.gender === 'M' ? true : false"
      :name="studentCredential.user.name"
      :uuid="uuid"
      :builderLayoutId="
        studentCredential.main_product.custom_properties.layouts[0].id
      "
      :type_id="
        studentCredential.main_product.types[studentCredential.user.gender]
      "
      :custom_dedicatoria="''"
      :have_dedication="true"
      :is_subscription="false"
      :language="'pt'"
      @completed="avatarMethods"
      :custom_mooner="true"
      v-if="studentCredential.main_product.brand.name.includes('Mooney')"
    />

    <Avatar
      :character_slug="studentCredential.main_product.sku"
      :character_id="studentCredential.main_product.character_id"
      :product_id="studentCredential.main_product.builder_id"
      :is_male="studentCredential.user.gender === 'M' ? true : false"
      :name="studentCredential.user.name"
      :uuid="uuid"
      :builderLayoutId="
        studentCredential.custom_properties.main_product.layouts[0].id
      "
      :type_id="
        studentCredential.main_product.types[studentCredential.user.gender]
      "
      :custom_dedicatoria="studentCredential.dedication"
      :is_subscription="false"
      :language="'pt'"
      @completed="avatarMethods"
      :custom_mooner="true"
      v-else
    />

    <!-- <vuestic-modal :show.sync="show" ref="modal" v-bind:medium="true" :noButtons="true">
     
      <div class="va-row">
        <div class="flex md8 xs12 modal-text no-padding">
          <h1 slot="title"></h1>
          <h1>Editar Avatar</h1>


          O avatar do <b>{{ characterName }}</b> foi alterado.
         
          <br><br>
          A atualização é somente para esse ou<br>
           para todos os próximos Kits?
        </div>

        <div class="flex md4 xs12 modal-avatar">
          <img
            v-for="customization in customizations"
            :key="customization.categoryId"
            :src="customization.optionAsset"
            :style="[
              {zIndex : (customization.optionZIndex != null) ? customization.optionZIndex : customization.categoryZIndex}
            ]"
          >
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="clearfix"></div>
      <hr>
      <div class="modal-footer">
        <button class="btn btn-primary btn-detail" @click="updateAll">ATUALIZAR TODOS</button>
        <button class="btn btn-primary" @click="updateThis">ATUALIZAR ESSE</button>
      </div>
    </vuestic-modal> -->
  </div>
</template>

<script>
const axios = require("axios");

import { mapGetters } from "vuex";
//import CustomizationView from 'src/components/kits/avatar/CustomizationView.vue'

export default {
  name: "AvatarStudent",
  components: {
    //CustomizationView
  },
  computed: {
    ...mapGetters({
      studentCredential: "studentCredential",
      // ...
    }),
  },

  data() {
    return {
      show: true,
      customizations: {},
      uuid: "",
      characterName: "",
      characterIsMale: "",
      characterDedication: "",
      //subscription: null,
    };
  },

  mounted() {
    //this.subscription = userCredential.subscription
    //document.getElementsByClassName("ddh-sidebar")[0].style.display = 'none'

    this.uuid = this.studentCredential.uuid;
  },

  methods: {
    avatarMethods(payload) {
      this.customizations = payload.updatedCustomizations;
      this.uuid = payload.updatedUuid;
      this.characterName = payload.characterName;
      this.characterIsMale = payload.characterIsMale;
      this.characterDedication = payload.characterDedication;
      //this.$refs.modal.open()

      this.saveUser(this.uuid);
    },

    saveUser(uuid) {
      const self = this;

      let config = {
        headers: {
          Authorization: "Bearer " + this.studentCredential.token,
        },
      };

      let product = {
        builderCharacterId: uuid,
        builderProductId:
          this.studentCredential.subscription.product.builderProductId,
        isStudent: true,
      };

      //self.$router.push({ name: 'preview', params: { product: product } })

      self.$router.push({
        name: "preview",
        params: {
          product: product,
          isTeacher: self.isTeacher,
          grade: self.grade,
        },
      });

      // axios
      // 	.post(
      // 		process.env.VUE_APP_ROOT_API + "/students/save",
      // 		{
      // 			uuid: uuid,
      // 			gradeId: this.studentCredential.gradeId,
      // 			productId: self.studentCredential.subscription.product.productId,
      // 			triggerTrade: false,
      // 		},
      // 		config
      // 	)
      // 	.then(function (response) {
      // 		self.$router.push({
      // 			name: "preview",
      // 			params: {
      // 				product: product,
      // 				isTeacher: self.isTeacher,
      // 				grade: self.grade,
      // 			},
      // 		});
      // 	})
      // 	.catch(function (error) {
      // 		// console.log(error)
      // 		this.errors.push(error);
      // 	});
    },

    updateAll() {
      axios({
        method: "PUT",
        url:
          process.env.VUE_APP_ROOT_API +
          "/subscriptions/" +
          this.studentCredential.subscription.subscription.id +
          "/stack/character/all",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          CharacterName: this.characterName,
          BuilderCharacterId: this.uuid,
        },
      }).then((result) => {
        this.$router.push({ name: "next-kits" });
      });
    },

    updateThis() {
      axios({
        method: "PUT",
        url:
          process.env.VUE_APP_ROOT_API +
          "/subscriptions/" +
          this.studentCredential.subscription.subscription.id +
          "/stack/" +
          this.studentCredential.subscription.subscription.subscriptionStackId +
          "/character/",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          CharacterName: this.characterName,
          BuilderCharacterId: this.uuid,
        },
      }).then((result) => {
        this.$router.push({ name: "next-kits" });
      });
    },
  },

  beforeDestroy() {
    //document.getElementsByClassName("ddh-sidebar")[0].style.display = 'block'
  },
};
</script>

<style scoped lang="scss"></style>
